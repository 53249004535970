import { useState, useEffect } from 'react';
import { render } from 'react-dom';
const $ = jQuery;
import 'swiper/css/bundle'; // Swiper CSS

import DisplayPosts from './Components/Frontend/DisplayPosts';
import Pagination from './Pagination';
import './style.scss';
import { sliderConfig, setSliderHeight, tickerConfig } from './utils/configs';

// Advanced Posts Block
document.addEventListener('DOMContentLoaded', () => {
	const postsEls = document.querySelectorAll('.wp-block-ap-block-posts');
	postsEls.forEach(postEl => {
		const attributes = JSON.parse(postEl.dataset.attributes);
		const { totalPosts } = JSON.parse(postEl.dataset.extra);
		const { layout } = attributes;

		// Slider
		if ('slider' === layout) {
			const sliderEl = document.querySelector(`#${postEl.id} .apbSliderPosts`);

			if (sliderEl) {
				new Swiper(sliderEl, sliderConfig(attributes));
				// new Swiper(sliderEl, {
				// 	...sliderConfig(attributes),
				// 	on: {
				// 		init: () => {
				// 			const slideWrapperEl = document.querySelector(`#${postEl.id} .apbSliderPosts .swiper-wrapper`);
				// 			slideWrapperEl.className = 'swiper-wrapper';
				// 			slideWrapperEl.style.gap = 0;
				// 		}
				// 	}
				// });

				// Slider Height
				setSliderHeight(postEl.id);
			}
		}


		// Ticker
		if ('ticker' === layout) {
			const tickerEl = document.querySelector(`#${postEl.id} .apbTickerPosts`);

			tickerEl && $(tickerEl).easyTicker(tickerConfig(attributes));
		}

		render(<RenderMain postEl={postEl} totalPosts={totalPosts} attributes={attributes} />, document.querySelector(`#${postEl.id} .apbPostsMain`));

		postEl?.removeAttribute('data-attributes');
		postEl?.removeAttribute('data-extra');
	});
});

const RenderMain = ({ postEl, totalPosts, attributes }) => {
	const { cId, layout, isPostsPerPageAll, postsPerPage, postsOffset, isPagination, paginationPrevLabel, paginationNextLabel, fImgSize = 'full', metaDateFormat = 'M j, Y' } = attributes;

	const [posts, setPosts] = useState([]);

	const postsMainPHEl = document.querySelector(`#${postEl.id} .apbPostsMainPH`);

	// Remove PHP posts if has posts
	useEffect(() => {
		posts?.length && postsMainPHEl?.remove();
	}, [posts, postsMainPHEl]);

	return <>
		{posts?.length ? <DisplayPosts posts={posts} attributes={attributes} clientId={cId} /> : null}

		<Pagination attributes={{ layout, isPostsPerPageAll, postsPerPage, isPagination, paginationPrevLabel, paginationNextLabel }} totalCount={totalPosts} onChange={val => {
			// window.scrollTo({
			// 	top: postEl.offsetTop,
			// 	behavior: 'smooth',
			// });

			// Fetch posts on page click
			fetch(`${apbPageData?.ajaxUrl}?action=apb_get_posts&cId=${cId}&pageNumber=${val}&postsPerPage=${postsPerPage}&postsOffset=${postsOffset}&fImgSize=${fImgSize}&metaDateFormat=${metaDateFormat}`)
				.then(res => res.json())
				.then(data => setPosts(data));
		}} />
	</>
}