import { truncate, strLength } from '../utils/functions';

const PostExcerpt = ({ post, attributes }) => {
	const { excerpt, content } = post;
	const { isExcerpt, isExcerptFromContent, isEllipsisOnExcerpt, excerptLength } = attributes;

	const finalExcerpt = (!isExcerptFromContent && excerpt) ? excerpt : content;
	const ellipsis = (isEllipsisOnExcerpt && (strLength(finalExcerpt) > excerptLength)) ? '...' : '';

	return isExcerpt ? <div className='apbExcerpt apbInnerContent' dangerouslySetInnerHTML={{ __html: `${truncate(finalExcerpt, excerptLength)}${ellipsis}` }} /> : null;
};
export default PostExcerpt;