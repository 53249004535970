import PostMetaAuthor from './PostMetaAuthor';
import PostMetaDate from './PostMetaDate';
import PostMetaCategory from './PostMetaCategory';
import PostMetaReadTime from './PostMetaReadTime';
import PostMetaComment from './PostMetaComment';

const Meta = ({ post, attributes }) => {
	const { isMeta } = attributes;

	return isMeta && <div className='apbMeta'>
		<PostMetaAuthor post={post} attributes={attributes} />
		<PostMetaDate post={post} attributes={attributes} />
		<PostMetaCategory post={post} attributes={attributes} />
		<PostMetaReadTime post={post} attributes={attributes} />
		<PostMetaComment post={post} attributes={attributes} />
	</div>;
};
export default Meta;